import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

    private mpSiteTitle = 'Management Portal';
    private cpSiteTitle = 'Client Portal';
    private siteTitle = this.mpSiteTitle;

    imageElements = [
        'https://s3-ap-southeast-2.amazonaws.com/cdn.enquire.net.au/s/enquire-2/tenants/',
        'default',
        '/logo-mp.png'
    ];

    protected defaultImageUrl = this.imageElements.join('');
    protected displayImageUrl = this.defaultImageUrl;

    public ngOnInit() {
        console.log('defaultImageUrl ', this.defaultImageUrl);

        const hostname = window.location.hostname;
        console.log('hostname ', hostname);

        const subDomain = hostname.substring(0, hostname.indexOf('.')).toLowerCase();
        console.log('subDomain ', subDomain);

        const clientPortalSite = subDomain.endsWith('-cp');
        console.log('clientPortalSite ', clientPortalSite);

        let tenantCode;

        if (clientPortalSite) {
            this.siteTitle = this.cpSiteTitle;
            tenantCode = subDomain.substring(0, this.siteTitle.length - 3);
        } else {
            tenantCode = subDomain;
        }

        this.imageElements[1] = tenantCode;

        if (this.imageExists(this.imageElements.join(''))) {

        } else {
            this.displayImageUrl = this.defaultImageUrl;
        }
    }

    private imageExists(imageUrl: string): boolean {
        const http = new XMLHttpRequest();
        http.open('HEAD', imageUrl, false);
        http.send();

        return http.status !== 404;
    }
}
